body {
    overflow: auto;
    overflow-x: hidden;
}
p, ul, a {
  font-size: rem-calc(24);
}
a {
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s, border-color 0.3s;
  &:hover, &:focus {
    color: #746f8a;
  }
}

section > .grid-container,
footer > .grid-container,
header.grid-container {
  @include breakpoint(small only) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.section {
    min-height: 70vh;
    display: flex;
    align-items: center;
    & > .grid-container {
        flex: 1;
    }
}

#threejs {
    position: absolute;
    width: 50vw;
    min-height: 75vh;
    height: 100%;
    top: 0;
    right: 0;
    canvas {
        position: relative;
    }
}

.gradient-text {
    background-image: linear-gradient(to right, #4f9edf 0%, #773fef 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    display: inline-block;
}

.hero-wrapper {
    position: relative;
    // background-image: radial-gradient(circle at 75% 50%, #232B59 0%, #101010 20%, #0a0a0a 40%);
    &.inner {
        background: none;
    }
}
.hero {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 1;
    @include breakpoint(medium) {
        min-height: rem-calc(520);
    }
    @include breakpoint(large) {
        min-height: rem-calc(580);
    }
    h1 {
        font-weight: 600;
        line-height: 1.2;
        font-size: rem-calc(36);
        margin-bottom: rem-calc(32);
        @include breakpoint(medium) {
            font-size: rem-calc(56);
        }
        @include breakpoint(large) {
            font-size: rem-calc(65);
        }
    }
    .hero-lead p {
        font-size: rem-calc(24);
        line-height: 40px;
        margin-bottom: rem-calc(46);
    }
}

.sectiomn-wrapper {
    position: relative;
    @include breakpoint(small only) {
        margin-bottom: rem-calc(32);
    }
}
.section-title {
    font-size: rem-calc(46);
    font-weight: 300;
    @include breakpoint(medium) {
        font-size: rem-calc(60);
        transform: rotate(90deg);
        position: absolute;
        right: 0;
        left: 0;
        transform-origin: 42px 42px;
    }
}

#HeroWrapper {
    padding-bottom: rem-calc(40);
    @include breakpoint(medium) {
        padding-bottom: rem-calc(60);
    }
}

.home-featured {
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(100);
    @include breakpoint(medium) {
        padding-top: rem-calc(80);
        padding-bottom: rem-calc(150);
    }
    h1 {
        margin-bottom: rem-calc(66);
        background-image: gradient(#4f9edf, #773fef);
        @include breakpoint(small only) {
            font-size: rem-calc(46);
        }
    }
}
.featured-projects__single-project-wrapper {
    a {
        color: $white;
        border-bottom: 1px solid transparent;
        transition: border 0.3s ease;
        margin-bottom: rem-calc(14);
        display: inline-block;
        &:hover {
            border-bottom-color: $white;
        }
        &>h2 {
            margin-bottom: 0;
        }
    }
    h2 {
        font-size: rem-calc(30);
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: rem-calc(14);
    }
    .description {
        color: #8c96a8;
        font-size: rem-calc(16);
    }
    img {
        transition: transform 0.3s ease;
    }
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
    @include breakpoint(small only) {
        margin-bottom: rem-calc(48);
    }
}
.featured-projects__single-project-wrapper__image {
    max-height: 430px;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: rem-calc(20);
    @include breakpoint(medium) {
        margin-bottom: rem-calc(36);
    }
}

.home-services {
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(40);
    @include breakpoint(medium) {
        padding-bottom: rem-calc(90);
        padding-bottom: rem-calc(150);
    }
    h3 {
        font-size: rem-calc(27);
        font-weight: 600;
        margin-bottom: rem-calc(18);
        @include breakpoint(medium) {
            margin-bottom: rem-calc(24);
        }
    }
    
    ul {
        font-size: rem-calc(22);
        font-weight: 300;
        line-height: 1.8;
        color: #8c96a8;
        @include breakpoint(small only) {
            margin-bottom: rem-calc(32);
        }
    }
    .gradient-text {
        background-image: linear-gradient(to right, #fff -41%, #7cbff6 -10%, #2a37c6 21%, #8a2ac6 47%);
    }
}


.home-about {
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(100);
    @include breakpoint(medium) {
        padding-bottom: rem-calc(90);
        padding-bottom: rem-calc(130);
    }
    @include breakpoint(large) {
        padding-bottom: rem-calc(120);
        padding-bottom: rem-calc(200);
    }
    h4 {
        font-size: rem-calc(24);
        font-weight: 600;
    }
    p {
        font-size: rem-calc(24);
        // font-weight: 400;
        color: #a1a1a6;
    }
    .gradient-text {
        background-image: gradient(#0aedd2, #4f9edf);
    }
}

.home-tech {
    background-color: $white;
    color: #aaaaaa;
    padding: rem-calc(80 0 60);
    @include breakpoint(medium) {
        padding: rem-calc(140 0 120);
    }
    @include breakpoint(large) {
        padding: rem-calc(170 0 150);
    }
    h5 {
        text-align: center;
        // font-size: rem-calc(14);
        @include breakpoint(medium) {
            font-size: rem-calc(14);
            text-align: left;
        }
    }
    .logos {
        display: flex;
        align-items: center;
        margin-bottom: rem-calc(16);
        .cell {
            align-items: center;
            margin-bottom: rem-calc(40);
            @include breakpoint(small only) {
                display: flex;
                min-height: rem-calc(80);
                margin-bottom: rem-calc(24);
                justify-content: center;
            }
        }
    }
    .gradient-text {
        background-image: gradient(#5bf1bb, #0ab6ed);
    }
}
.tech-logo {
    max-width: 80px;
    max-height: 80px;
    // &.logo-1 {
    //     max-width: 58px;
    // }
    // &.logo-8 {
    //     max-width: 69px;
    // }
    // &.logo-9 {
    //     max-width: 58px;
    // }
    // &.logo-11 {
    //     max-width: 90px;
    // }
    // &.logo-12 {
    //     max-width: 106px;
    // }
    // &.logo-13 {
    //     max-width: 58px;
    // }
    // &.logo-14 {
    //     max-width: 166px;
    // }
    // &.logo-14 {
    //     max-width: 131px;
    // }
}

.home-workflow {
    padding: rem-calc(72 0);
    position: relative;
    overflow: hidden;
    // background-image: radial-gradient(circle at 75% 40%, #2b4180 0%, #101010 30%, #0a0a0a 50%);
    @include breakpoint(medium) {
        padding: rem-calc(100 0);
    }
    @include breakpoint(large) {
        padding: rem-calc(166 0);
    }
    h1 {
        font-weight: 600;
        line-height: 1.1;
        font-size: rem-calc(52);
        margin-bottom: rem-calc(50);
        @include breakpoint(medium) {
            font-size: rem-calc(75);
            margin-bottom: rem-calc(70);
        }
        @include breakpoint(large) {
            font-size: rem-calc(90);
            margin-bottom: rem-calc(90);
        }
    }
    .lead {
        font-weight: 600;
        margin-bottom: 0;
        font-size: rem-calc(30);
        @include breakpoint(medium) {
            font-size: rem-calc(36);
        }
        @include breakpoint(large) {
            font-size: rem-calc(45);
        }
    }
    .gradient-text {
        background-image: linear-gradient(to right, #2480b4 0%, #07f1e3 50%);
    }
}

.home-testimonials {
    background-color: #fcfcfc;
    color: $black;
    // min-height: rem-calc(580);
    padding: rem-calc(70 0 120);
    @include breakpoint(medium) {
        padding: rem-calc(170 0 160);
    }
    @include breakpoint(large) {
        padding: rem-calc(200 0 170);
    }
}
.testimonials-carousel {
    height: 100%;
    // width: 1042px;
    // min-height: rem-calc(580);
    // display: flex;
    .testimonial-item {
        width: 100%;
        // padding-right: 30px;
        &>.grid-x {
            align-items: center;
        }
    }
}
.testimonial-logo {
    justify-content: center;
    display: flex;
}
.testimonial-logo__wrapper {
    border-radius: 12px;
    box-shadow: 0 12px 30px 0 rgba($black, 0.05);
    background-color: $white;
    @include breakpoint(small only) {
        margin-bottom: rem-calc(32);
    }
    // width: 170px;
    // height: 170px;
    padding: 20px;
    img {
        max-width: 130px;
        max-height: 130px;
    }
}
.testimonial-text__content {
    p {
        font-size: rem-calx(21);
        font-weight: 300;
        margin-bottom: rem-calc(30);
        @include breakpoint(medium) {
            font-size: rem-calc(30);
            margin-bottom: rem-calc(40);
        }
    }
}
.testimonial-text__name {
    font-size: rem-calc(14);
    color: #565555;
}
.testimonial-text__position {
    font-size: rem-calc(14);
    color: #aaaaaa;
}
.flickity-page-dots {
    bottom: -50px;
    @include breakpoint(medium) {
        left: 33.33333%;
        width: 66.66667%;
        justify-content: flex-start;
    }
}
.flickity-page-dot {
    transition: width 0.3s ease;
    border-radius: 0;
    width: 20px;
    height: 2px;
    background-color: #c4c4c4;
    &.is-selected {
        width: 43px;
        background-color: #c4c4c4;
    }
    &:focus {
        box-shadow: none;
    }
    &:first-child {
        margin-left: 0;
    }
}

.home-contact {
    padding: rem-calc(80 0 60);
    position: relative;
    overflow: hidden;
    // background-image: radial-gradient(circle at 60% 40%, #2c0c57 0%, #101010 40%, #0a0a0a 60%);
    @include breakpoint(medium) {
        padding: rem-calc(250 0 150);
    }
    @include breakpoint(large) {
        padding: rem-calc(400 0 180);
    }
    p, a {
        line-height: 1;
        margin-bottom: 0;
        font-weight: 600;
        font-size: rem-calc(50);
        @include breakpoint(medium) {
            font-size: rem-calc(75);
        }
        @include breakpoint(large) {
            font-size: rem-calc(90);
        }
    }
    .gradient-text {
        background-image: linear-gradient(to right, #2480b4 0%, #07f1e3 50%);
        &:after {
            content: "";
            background-image: linear-gradient(to right, #2480b4 0%, #07f1e3 50%);
            width: 100%;
            height: 2px;
            display: block;
            opacity: 1;
            transition: opacity 0.3s;
        }
        &:hover {
            &:after {
                opacity: 0;
            }
        }
    }
}


#Services {
	background-color: #161223;
    padding-top: rem-calc(60);
    @include breakpoint(medium) {
        padding-top: rem-calc(90)
    }
    @include breakpoint(large) {
        padding-top: rem-calc(115)
    }
    p {
        margin-bottom: 0;
        color: #8c8a95;
        font-weight: bold;
    }
    ul {
        @include breakpoint(small only) {
            margin-bottom: rem-calc(40);
        }
    }
}

body:not(.chrome.win32) {
    .blurred-shapes {
        position: absolute;
        width: 100vw;
        height: 100%;
        pointer-events: none;
        top: 0;
        left: 0;
        .circle {
            width: 590px;
            height: 590px;
            position: absolute;
            margin: auto;
            border-radius: 50%;
            filter: blur(100px);
            &.violet {
                width: 390px;
                height: 390px;
                background: #5418a1;
                left: 10vw;
                right: 20vw;
                top: 100px;
                opacity: 0.6;
                animation: circle-violet 5s infinite;
            }
            &.blue {
                background: #4b6ecc;
                left: 40vw;
                right: 10vw;
                top: 160px;
                opacity: 0.5;
                animation: circle-blue 5s infinite;
            }
            &.dark-blue {
                background: #2b4180;
                left: 40vw;
                right: 0;
                bottom: 160px;
                animation: circle-dark-blue 5s infinite;
            }
            &.purple {
                background: #2c0c57;
                left: 40vw;
                right: 0;
                bottom: 160px;
                opacity: 0.8;
                animation: circle-purple 10s infinite;
            }
        }
    }
}
body.win32 {
    .blurred-shapes {
        opacity: 0.8;
    }
}
@keyframes circle-violet {
    0% {
        left: 10vw;
        top: 100px;
    }
    
    50% {
        left: 5vw;
        top: 260px;
    }
    100% {
        left: 10vw;
        top: 100px;
    }
}
@keyframes circle-blue {
    0% {
        left: 40vw;
        top: 160px;
    }
    50% {
        left: 46vw;
        top: 60px;
    }
    100% {
        left: 40vw;
        top: 160px;
    }
}
@keyframes circle-dark-blue {
    0% {
        left: 40vw;
        bottom: 160px;
    }
    50% {
        left: 30vw;
        bottom: 190px;
    }
    
    100% {
        left: 40vw;
        bottom: 160px;
    }
}
@keyframes circle-purple {
    0% {
        left: 40vw;
        bottom: 160px;
    }
    50% {
        left: 10vw;
        bottom: 190px;
    }
    
    100% {
        left: 40vw;
        bottom: 160px;
    }
}