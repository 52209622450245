footer {
	background-color: #161223;
	padding: rem-calc(60 0 160);
	@include breakpoint(medium) {
		padding: rem-calc(100 0 250);
	}
	p {
		margin-bottom: 0;
		line-height: 1.2;
	}
	a {
		color: $white;
		&.underlined {
			text-decoration: underline;
		}
	}
  .cell {
    @include breakpoint(small only) {
      margin-bottom: rem-calc(32);
    }
  }
}
.fanta-hr {
  width: rem-calc(45);
  border: none;
  background-color: $white;
  height: 1px;
  margin-bottom: rem-calc(25);
  margin-left: 0;
}