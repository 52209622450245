.ProjectPage {
    position: relative;
}
.background-wrapper {
    background-image: radial-gradient(circle at 50% 280px, #232B59 0%, #101010 260px, #0a0a0a 280px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem-calc(600);
}
.project-section {
    padding: rem-calc(0 0 30);
    @include breakpoint(medium) {
        padding: rem-calc(0 0 30);
    }
    @include breakpoint(large) {
        padding: rem-calc(0 0 30);
    }
    h2 {
        font-weight: bold;
        font-size: rem-calc(36);
        margin-bottom: rem-calc(22);
        margin-top: rem-calc(25);
        @include breakpoint(medium) {
            font-size: rem-calc(48);
            margin-bottom: rem-calc(28);
            margin-top: rem-calc(35);
        }
        @include breakpoint(large) {
            font-size: rem-calc(60);
            margin-bottom: rem-calc(36);
            margin-top: rem-calc(50);
        }
    }
    .gradient-text {
        background-image: gradient(#87dbff, #8b41f4);
    }
}
.copy-lead {
    margin-bottom: rem-calc(36);
    @include breakpoint(medium) {
        margin-bottom: rem-calc(42);
        padding-right: 8%;
    }
    @include breakpoint(large) {
        padding-right: 16%;
    }
}
.project-section__row {
    margin-bottom: rem-calc(30);
    &:last-child {
        margin-bottom: 0;
    }
}
.project-copy {
    margin-bottom: rem-calc(50);
    @include breakpoint(medium) {
        margin-bottom: rem-calc(80);
    }
    @include breakpoint(large) {
        margin-bottom: rem-calc(120);
    }
}
.project-contact {
    padding: rem-calc(40 0 80);
    @include breakpoint(medium) {
        padding: rem-calc(60 0 120);
    }
    @include breakpoint(large) {
        padding: rem-calc(90 0 160);
    }
    a {
        line-height: 1.2;
        font-weight: bold;
        font-size: rem-calc(36);
        margin-bottom: rem-calc(40);
        @include breakpoint(medium) {
            font-size: rem-calc(48);
            margin-bottom: rem-calc(28);
        }
        @include breakpoint(large) {
            font-size: rem-calc(60);
            margin-bottom: rem-calc(36);
        }
    }
    .gradient-text {
        background-image: gradient(#87dbff, #8b41f4);
        &:after {
            content: "";
            background-image: linear-gradient(to right, #87dbff 0%, #8b41f4 100%);
            width: 100%;
            height: 2px;
            display: block;
            opacity: 1;
            transition: opacity 0.3s;
        }
        &:hover {
            &:after {
                opacity: 0;
            }
        }
    }
}

.project-credits {
    background-color: $white;
    color: $black;
    padding: rem-calc(40 0 80);
    @include breakpoint(medium) {
        padding: rem-calc(60 0 120);
    }
    @include breakpoint(large) {
        padding: rem-calc(90 0 160);
    }
    h3, h4, h5 {
        font-weight: 600;
    }
    h4 {
        margin-bottom: rem-calc(20);
    }
    h5 {
        font-size: rem-calc(18);
    }
    p, ul {
        font-weight: 300;
    }
    p {
        font-size: rem-calc(16);
    }
    ul {
        font-size: rem-calc(18);
        margin-bottom: rem-calc(32);
    }
}
.project-credits__description {
    @include breakpoint(medium) {
        padding-right: rem-calc(32);
    }
    @include breakpoint(large) {
        padding-right: rem-calc(50);
    }
    ul {
        list-style: none;
        margin-left: 0;
    }
}