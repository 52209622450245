.container {
  @include breakpoint(small) {
    max-width: 540px;
  }
  @include breakpoint(medium) {
    max-width: 720px;
  }
  @include breakpoint(large) {
    max-width: 90rem;
  }
  @include breakpoint(xlarge) {
    max-width: 80rem;
  }
}
img {
  max-width: 100%;
}
label {
  font-size: 15px;
  margin-bottom: 3px;
}

.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}

.grid-x {
  & > .auto {
    flex: 0 0 auto;
  }
}
.cell {
  position: relative;
}

.button {
  line-height: 1;
  &.rounded {
    border-radius: 36px;
  }
  &.hollow {
    background-color: transparent;
    border-color: #c1c1c1;
    color: #4c4c4c;
  }
  &.alt {
    position: relative;
    background: none;
    overflow: hidden;
    &:hover,
    &:focus {
      color: $black;
      background: none;
      &:after {
        top: -100%;
        transition: top 0.2s ease-in;
      }
    }
    &:after,
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 300%;
      border-radius: 100%;
    }
    &:before {
      background-color: $primary-color;
      top: -100%;
      left: 0;
      z-index: -2;
    }
    &:after {
      background-color: $white;
      top: 100%;
      left: 0;
      z-index: -1;
      transition: top 0.3s ease-out;
    }
  }
}
.form-inline {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  @include breakpoint(small only) {
    justify-content: center;
  }
}
.text-small-center {
  @include breakpoint(small only) {
    text-align: center;
  }
}
.text-medium-left {
  @include breakpoint(medium) {
    text-align: left;
  }
}
.text-medium-right {
  @include breakpoint(medium) {
    text-align: right;
  }
}

.justify-content-small-center {
  @include breakpoint(small only) {
    justify-content: center;
  }
}
.justify-content-medium-end {
  @include breakpoint(medium) {
    justify-content: flex-end;
  }
}

.date-picker-wrapper {
  .month-wrapper {
    table {
      .day {
        padding: rem-calc(8 8);
        font-size: 14px;
        &.day-occupied {
          background-color: #d04040;
          color: $white;
        }
      }
    }
  }
  .date-range-length-tip {
    padding: 6px 10px;
    background-color: $white;
    color: #d04040;
    margin-top: -8px;
    margin-left: -3px;
    &:after {
      border-top-color: $white;
    }
  }
}